import { useEffect, useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import {
  convertPlainObjectFieldsToFirestoreTypes,
  getMinutesInBatch,
  PlaytimeBatchInfo,
} from '@playback-rewards/shared-libraries'

import GenericPlaytimeTask from 'pages/GameDetails/components/SpecialOffer/components/GenericOffer/GenericPlaytimeTask'
import { getActiveIndex } from 'utils/genericOffer'
import BottomButton from '../common/BottomButton'
import { GAME_CARD_OFFER_TYPE, OFFER_QUALITY, OFFER_TYPE } from '../../constants'
import OfferTask from '../offerTask'
import QualityTag from './QualityTag'
import AnimatedPiggy from './AnimatedPiggy'

const OfferCardFooter = ({
  type,
  isValidating,
  activeTask,
  quality,
  game,
  handleTryGame,
  activatedOffer,
}) => {
  if (type === GAME_CARD_OFFER_TYPE.ACTIVATED) {
    return (
      <ActivatedOffer
        isValidating={isValidating}
        activeTask={activeTask}
        activatedOffer={activatedOffer}
      />
    )
  }

  return (
    <>
      <QualityTag qualityName={quality} />
      {game.isReferral ? (
        <Typography variant="body2" sx={styles.text}>
          {game.valueStatement}
        </Typography>
      ) : (
        type === GAME_CARD_OFFER_TYPE.FEATURED && (
          <FeaturedOffer handleTryGame={handleTryGame} activatedOffer={activatedOffer} />
        )
      )}
    </>
  )
}

const ActivatedOffer = ({ isValidating, activeTask, activatedOffer }) => {
  const playtimeBatchInfo = useMemo(() => {
    if (!activatedOffer || !activatedOffer?.batchPlaytimeConfig) {
      return
    }
    // TODO: We should convert activatedOffer when we get it from api.
    // But this requries refactoring how we implmented the timestamps.
    // This change is required to support npm shared package
    const activateOfferCopy = structuredClone(activatedOffer)
    convertPlainObjectFieldsToFirestoreTypes(activateOfferCopy)
    return new PlaytimeBatchInfo(activateOfferCopy)
  }, [activatedOffer])
  const batches = useMemo(
    () => Array.from({ length: activatedOffer?.batchPlaytimeConfig?.batchCount || 0 }),
    [activatedOffer?.batchPlaytimeConfig?.batchCount]
  )
  const [activeIndex, setActiveIndex] = useState(-1)
  useEffect(
    () => setActiveIndex(getActiveIndex(batches, playtimeBatchInfo)),
    [batches, activatedOffer, playtimeBatchInfo]
  )
  const minutesInBatch = getMinutesInBatch(activatedOffer?.batchPlaytimeConfig) || 0
  const remainingMinutes = Math.ceil(
    playtimeBatchInfo?.getRemainingMinutesInBatch(activeIndex) || 0
  )
  const progress = useMemo(
    () => Math.round(((minutesInBatch - remainingMinutes) / minutesInBatch) * 100),
    [minutesInBatch, remainingMinutes]
  )
  const isCompleted = useMemo(() => progress >= 100, [progress])
  const index = useMemo(() => {
    if (isCompleted) {
      return Math.min(activeIndex + 1, batches.length - 1)
    }

    return activeIndex
  }, [isCompleted, activeIndex])

  if (isValidating) {
    return <QualityTag qualityName={OFFER_QUALITY.FEATURED} text="Validating install" />
  }

  if (activatedOffer.offerType === OFFER_TYPE.GENERIC_OFFER && activeIndex > -1) {
    return (
      <div className="genericOffer">
        <GenericPlaytimeTask
          key={`GenericOfferBatch ${activeIndex + index}`}
          index={index}
          activeIndex={activeIndex}
          batchPlaytimeConfig={activatedOffer?.batchPlaytimeConfig}
          playtimeBatchInfo={playtimeBatchInfo}
          onRefresh={() => setActiveIndex(getActiveIndex(batches, playtimeBatchInfo))}
        />
      </div>
    )
  }
  if (activeTask) {
    return <OfferTask {...activeTask} customClassName="activatedOfferCardTask" />
  }

  return null
}

const FeaturedOffer = ({ handleTryGame, activatedOffer }) => (
  <BottomButton style={styles.featuredButton} handleClick={handleTryGame}>
    <Typography variant="body2" sx={styles.featuredButtonText}>
      {activatedOffer ? 'Play! ' : 'Try Game! '}
    </Typography>
    <AnimatedPiggy />
  </BottomButton>
)

const styles = {
  featuredButton: {
    width: 'max-content',
    padding: '4px 7px',
    background: '#FF6492',
    display: 'flex',
    gap: '4px',
    '&:hover': {
      background: '#db517a',
    },
  },
  featuredButtonText: {
    color: '#ffffff',
    fontWeight: 600,
  },
  text: {
    color: '#ffffff',
    margin: 'auto 0',
  },
}

export default OfferCardFooter
