import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { MMPTask } from '@playback-rewards/shared-schemas'

//components
import GenericPlaytimeTask from './GenericPlaytimeTask'
import GenericMMPTask from './GenericMMPTask'
//constants
import { CLAIM_SPECIAL_REWARD_REASON } from 'constants/index'
//types
import { GenericOfferProps } from 'types'
//utils
import { getActiveIndex } from 'utils/genericOffer'

const GenericOffer = ({ title, gameDetails, playtimeBatchInfo, onRefresh }: GenericOfferProps) => {
  //Playtime
  const batchPlaytimeConfig = useMemo(
    () => gameDetails?.specialOfferDetails?.batchPlaytimeConfig,
    [gameDetails?.specialOfferDetails?.batchPlaytimeConfig]
  )
  const batches: number[] = useMemo(
    () => Array.from({ length: batchPlaytimeConfig?.batchCount || 0 }),
    [batchPlaytimeConfig?.batchCount]
  )
  const activeIndex = useMemo(
    () => getActiveIndex(batches, playtimeBatchInfo),
    [batches, playtimeBatchInfo]
  )
  const allPlaytimeTasksCompleted =
    gameDetails?.activatedOffer?.isInstallAttributed && activeIndex < 0
  //MMP
  const mmpEventTasks = useMemo(() => {
    const tasks = (gameDetails?.activatedOffer?.tasks ||
      gameDetails?.specialOfferDetails?.tasks) as MMPTask[]

    return tasks?.filter((task) => task.taskType === CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT)
  }, [gameDetails?.activatedOffer?.tasks, gameDetails.specialOfferDetails?.tasks])
  const allMMPEventTasksCompleted = useMemo(
    () => mmpEventTasks.every((t) => t.completed),
    [mmpEventTasks]
  )

  if (allPlaytimeTasksCompleted && (mmpEventTasks?.length <= 0 || allMMPEventTasksCompleted)) {
    return
  }

  return (
    <div className="gameDetailsSpecialOfferLoginRewards">
      <div className="gameDetailsSpecialOfferPlaytimeRewardsTitle">
        <Typography variant="h3" mb={1} mx={0.5}>
          {title}
        </Typography>
      </div>
      {batchPlaytimeConfig &&
        !allPlaytimeTasksCompleted &&
        batches.map((_, index) => {
          return (
            index >= activeIndex &&
            index <= activeIndex + 2 && (
              <GenericPlaytimeTask
                key={`playtime-${index}`}
                index={index}
                activeIndex={activeIndex}
                batchPlaytimeConfig={batchPlaytimeConfig}
                playtimeBatchInfo={playtimeBatchInfo}
                onRefresh={onRefresh}
              />
            )
          )
        })}
      {mmpEventTasks?.map((task, index) => {
        return (
          !task.completed && (
            <GenericMMPTask
              key={`mmpevent-${task.taskId}-${index}`}
              activatedOffer={gameDetails?.activatedOffer}
              allMMPTasks={mmpEventTasks}
              mmpTask={task}
            />
          )
        )
      })}
    </div>
  )
}

export default GenericOffer
