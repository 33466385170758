import { DateTime, Duration } from 'luxon'
import { PlaytimeBatchInfo } from '@playback-rewards/shared-libraries'

export const getActiveIndex = (
  batches: number[],
  playtimeBatchInfo: PlaytimeBatchInfo | undefined
) => {
  if (!playtimeBatchInfo?.activatedOffer?.isInstallAttributed) {
    return -1
  }
  return batches.findIndex((_, index) => {
    const batchStartTime = playtimeBatchInfo?.getBatchStartTime(index) || DateTime.now().toJSDate()
    const batchEndTime = playtimeBatchInfo?.getBatchEndTime(index) || DateTime.now().toJSDate()
    const luxonStartTime = DateTime.fromJSDate(batchStartTime)
    const luxonEndTime = DateTime.fromJSDate(batchEndTime)
    const luxonCurrentTime = DateTime.now()
    return luxonCurrentTime > luxonStartTime && luxonCurrentTime < luxonEndTime
  })
}

export const getTimeDifference = (endTime: DateTime, startTime: DateTime) => {
  return endTime.diff(startTime, ['hours', 'minutes', 'seconds'])
}

export const getTimeValue = (time: Duration | undefined) => {
  if (!time) {
    return ''
  } else if (time?.hours > 0) {
    return `${Math.round(time.hours)}hr`
  } else if (time.minutes > 0) {
    return `${Math.round(time.minutes)}min`
  } else if (time.seconds > 0) {
    return `${Math.round(time.seconds)}sec`
  }
  return ''
}
