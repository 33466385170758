import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Typography } from '@mui/material'

import { isLinkActive } from '../../../../utils/utils'
import { BOTTOM_NAVIGATION_TABS } from '../../../../constants'
import AuthContext from '../../../../context/AuthContext'

import './index.css'

const Footer = () => {
  const prevRoute = useLocation()

  const { onboardingStep, changeStep } = useContext(AuthContext)

  const checkNextPath = (route) => onboardingStep?.nextPath === route

  return (
    <footer className="gamesFooterWrap" style={{ background: "url('/images/background.png')" }}>
      <div className="gamesFooter">
        <Container maxWidth="sm" sx={styles.container}>
          <div className="gamesFooterInner">
            {BOTTOM_NAVIGATION_TABS.map(({ route, Icon, ActiveIcon, title }, key) => (
              <Link
                className={`gamesFooterLink ${isLinkActive(route) ? 'active' : ''}`}
                key={route + key}
                to={route}
                state={{ prevRoute }}
                {...(checkNextPath(route) && {
                  onClick: () => changeStep(true),
                })}
              >
                {isLinkActive(route) ? <ActiveIcon /> : <Icon />}
                <Typography variant="h5" sx={styles.title} className="gamesFooterLinkText">
                  {title}
                </Typography>
              </Link>
            ))}
          </div>
        </Container>
      </div>
    </footer>
  )
}

const styles = {
  title: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textDecoration: 'none',
    color: '#A1A1AA',
  },
}

export default Footer
