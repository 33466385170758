import React, { useContext, useEffect, useState } from 'react'
import { Container, DialogContent, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast, Toaster } from 'react-hot-toast'
import { useQuery, useQueryClient } from 'react-query'
import { decodeToken } from 'react-jwt'

import AuthContext from '../../context/AuthContext'
import {
  deleteSecondEmail,
  getSecondEmails,
  removeUserAccount,
  requestUserData,
} from '../../services/userService'
import Modal from '../../components/modal'
import ModalButtons from '../../components/userSettings/ModalButtons'
import LoaderContext from '../../context/LoaderContext'
import PageInner from '../../components/layout/PageInner'
import Layout from '../../components/layout/Details'
import { handleError, isUserFromMobileDevice, sendGA4Event, sendRNMessage } from '../../utils/utils'
import {
  ANALYTICS_EVENT,
  ERROR_TEXT,
  LOCAL_STORAGE,
  PRIVACY_POLICY_LINK,
  REACT_NATIVE_STATUS,
  SUCCESS_TEXT,
  SUPPORT_MAIL,
  TERMS_OF_SERVICE_LINK,
  VERIFICATION_STATUS,
} from '../../constants'
import { generateBaseUrlForSecondaryEmail } from '../../services/authService'
import useLocalStorage from '../../hooks/useLocalStorage'
import SelectModal from './components/SelectModal'
import UserSettingsItems from './components/Items'

import './index.css'

const DELETION_MODAL_KEY = {
  STANDARD: 0,
  VERIFIED: 1,
}

const UserSettings = () => {
  const { user, logout } = useContext(AuthContext)
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const { getItem } = useLocalStorage()

  const [modal, setModal] = useState(false)
  const [selectModal, setSelectModal] = useState(false)
  const [emailItems, setEmailItems] = useState([])
  const [modalProps, setModalProps] = useState({})
  const [needsRenewal, setNeedsRenewal] = useState(false)
  const [hasSeenToast, setSeenToast] = useState(false)

  const handleDeleteSecondEmail = (email) => {
    showLoader()

    deleteSecondEmail(email).finally(() => {
      queryClient.invalidateQueries({ queryKey: 'secondEmails' })
    })
  }

  const getItemsFromSecondEmails = (emails) => {
    const result = []
    let needsRenewal = false
    emails.map((email) => {
      if (email.grantNotification) {
        needsRenewal = true
      }
      result.push({
        value: email.connectedEmail,
        onClick: email.grantNotification && handleConnectSecondEmail,
        warning: email.grantNotification && '(needs renewal)',
        ...(email.connectedEmail !== user.email && {
          onDelete: () => handleDeleteSecondEmail(email.connectedEmail),
        }),
      })
    })

    setNeedsRenewal(needsRenewal)

    setEmailItems(result)
  }

  const { isLoading: loadingSecondEmails } = useQuery(
    'secondEmails',
    () => getSecondEmails().then(getItemsFromSecondEmails).finally(hideLoader),
    { onError: handleError }
  )

  useEffect(() => {
    loadingSecondEmails ? showLoader() : hideLoader()

    if (!loadingSecondEmails && emailItems.length && !hasSeenToast) {
      if (searchParams.has('connectError')) {
        if (needsRenewal) {
          toast.error(ERROR_TEXT.reconnect_email)
          return
        }

        toast.error(ERROR_TEXT[searchParams.get('connectError')])
      }

      if (searchParams.has('connectSuccess')) {
        toast.success(SUCCESS_TEXT.ADD_SECOND_EMAIL)
      }

      setSeenToast(true)
    }
  }, [loadingSecondEmails, emailItems.length])

  const navigate = useNavigate()

  const hideModal = () => setModal(false)

  const showSelectModal = () => {
    setSelectModal(true)
  }
  const hideSelectModal = () => setSelectModal(false)

  const handleRequestData = async () => {
    const userData = await requestUserData()

    if (window.ReactNativeWebView) {
      sendRNMessage({
        status: REACT_NATIVE_STATUS.REQUEST_MY_DATA,
        firstName: user.firstName,
        lastName: user.lastName,
        data: userData,
      })
    } else {
      const jsonStr = JSON.stringify(userData)
      const blob = new Blob([jsonStr], { type: 'application/json' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${user.firstName}-${user.lastName}-Data.json`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }

    sendGA4Event(ANALYTICS_EVENT.USER_DATA_EXPORT)
  }

  const handleGoBack = () => {
    let route = '/games'

    if (
      location.key !== 'default' &&
      !searchParams.has('connectError') &&
      !searchParams.has('connectSuccess')
    ) {
      route = -1
    }

    navigate(route)
  }

  const showModal = (key = DELETION_MODAL_KEY.STANDARD) => {
    const standardProps = {
      title: 'Are you sure?',
      text: (
        <>
          Playback only allows one account per person. If you choose to delete this account, you
          will not be able to create another account or recover this account in the future.
        </>
      ),
      actionButtons: (
        <ModalButtons
          hideModal={hideModal}
          button={{
            handleClick: () => handleRemoveAccount(DELETION_MODAL_KEY.STANDARD),
            text: 'Remove Account',
          }}
        />
      ),
    }

    switch (key) {
      case DELETION_MODAL_KEY.STANDARD: {
        setModalProps(standardProps)
        break
      }
      case DELETION_MODAL_KEY.VERIFIED: {
        setModalProps({
          title: 'You won’t be able to create another account!',
          text: (
            <DialogContent>
              <Typography variant="body2">
                Are you sure you want to delete your account? Playback only allows one account per
                person. Since you verified your current account, you will not be able to verify any
                additional accounts.
              </Typography>
              <Typography variant="body2" sx={styles.modalBoldText}>
                <br />
                Once you delete your account, you will not be able to recover it, verify a new
                account, or use Playback ever again.
              </Typography>
            </DialogContent>
          ),
          isCustomText: true,
          actionButtons: (
            <ModalButtons
              hideModal={hideModal}
              closeButtonText="Don’t delete my account"
              button={{
                handleClick: () => handleRemoveAccount(DELETION_MODAL_KEY.VERIFIED),
                text: 'Delete my account',
              }}
            />
          ),
        })
        break
      }
      default:
        {
          setModalProps(standardProps)
        }

        setModal(true)
    }
  }

  const handleRemoveAccount = async (modalKey = DELETION_MODAL_KEY.STANDARD) => {
    if (
      modalKey === DELETION_MODAL_KEY.STANDARD &&
      user.identityVerification.status === VERIFICATION_STATUS.VERIFIED
    ) {
      return showModal(DELETION_MODAL_KEY.VERIFIED)
    }

    hideModal()
    showLoader()
    await removeUserAccount()
      .catch(handleError)
      .finally(() => {
        logout()
        hideLoader()
      })
  }

  const handleAdminClick = () => navigate('/admin')

  const handleLogout = async () => {
    await sendGA4Event(ANALYTICS_EVENT.LOGOUT)
    showLoader()
    logout().finally(hideLoader)
  }

  const handleConnectSecondEmail = async () => {
    // const authLink = await generateAuthLink('auth/gmail/second-email', ['email']).catch(handleError)
    if (window.ReactNativeWebView) {
      setModal(false)
      const token = await getItem(LOCAL_STORAGE.JWT_TOKEN)
      const decodedToken = decodeToken(token)
      sendRNMessage({ status: REACT_NATIVE_STATUS.CONNECT_SECOND, userId: decodedToken.id })

      return
    }

    generateBaseUrlForSecondaryEmail().then((authLink) => (window.location.href = authLink))
  }

  const USER_SETTING_SECTIONS = [
    {
      title: 'General Info',
      items: [
        {
          label: 'Email',
          value: user?.email,
          onClick: null,
        },
        {
          label: 'Preferred Mobile App Store:',
          value: user?.platform,
          ...(!isUserFromMobileDevice() && { onClick: () => showSelectModal(false) }),
        },
        {
          label: 'Check Referral Details',
          onClick: () => navigate('/refer-a-friend'),
        },
      ],
    },
    {
      title: 'Receipt Collection Emails',
      items: [
        ...emailItems,
        {
          label: 'Add Email',
          onClick: handleConnectSecondEmail,
        },
      ],
    },
    {
      title: 'Support',
      items: [
        {
          label: 'FAQ',
          onClick: () => navigate('/faq'),
        },
        {
          label: 'Request My Data',
          onClick: handleRequestData,
        },
        {
          label: 'Contact Us:',
          value: <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>,
          customSX: styles.contactUs,
        },
      ],
    },
    {
      title: 'Legal',
      items: [
        {
          label: 'Privacy Policy',
          onClick: () => {
            window.location.href = PRIVACY_POLICY_LINK
          },
        },
        {
          label: 'Terms of Service',
          onClick: () => {
            window.location.href = TERMS_OF_SERVICE_LINK
          },
          customSX: styles.termsOfService,
        },
      ],
    },
    {
      items: [
        {
          label: 'Admin Panel',
          onClick: handleAdminClick,
          hideItem: user.role !== 'Admin',
        },
        {
          label: 'Log Out',
          onClick: handleLogout,
        },
        {
          label: 'Delete Account',
          onClick: showModal,
          customSX: styles.deleteAccount,
        },
      ],
    },
  ]

  return (
    <>
      <Toaster />
      <Modal showModal={modal} hideModal={hideModal} {...modalProps} />
      <SelectModal
        showLoader={showLoader}
        hideLoader={hideLoader}
        showModal={selectModal}
        hideModal={hideSelectModal}
        currentItem={user?.platform}
      />
      <PageInner customClassName={isLoading ? 'blured' : ''}>
        <Layout title={`${user.firstName} ${user.lastName}`} handleGoBack={handleGoBack}>
          <Container maxWidth="sm" sx={styles.container}>
            <div className="userSettingsInner">
              <Typography variant="inherit">Profile</Typography>
              {USER_SETTING_SECTIONS.map((item, key) => (
                <UserSettingsItems key={item.title + key} {...item} />
              ))}
            </div>
          </Container>
        </Layout>
      </PageInner>
    </>
  )
}

const styles = {
  title: {
    padding: '32px 16px',
    textAlign: 'center',
  },
  container: {
    padding: 0,
  },
  contactUs: {
    display: 'flex',
    justifyContent: 'unset',
    gap: '12px',
    paddingBottom: '12px',
    '@media (max-width: 380px)': {
      gap: '6px',
    },
  },
  termsOfService: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '12px',
  },
  deleteAccount: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    '& .MuiTypography-root': {
      color: '#EF4444',
    },
  },
  modalBoldText: {
    fontWeight: 700,
  },
}

export default UserSettings
